import PropTypes, { element, func } from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    Badge,
    CardTitle,
    FormGroup,
    Input,
    InputGroup,
    UncontrolledTooltip
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "../../assets/scss/style.css"
// import { Modal, Form, Button } from "react-bootstrap"
import { useFormik } from "formik"
import * as Yup from "yup"

import "flatpickr/dist/themes/material_blue.css"

import { withTranslation } from "react-i18next"

import { apiRoute, BASE_URL } from "routes/apiRoutes"
import axios from "axios"
import { dateformat, dateTimeformat } from "admin/commonFunction"
import Pagination, {
    bootstrap5PaginationPreset,
} from "react-responsive-pagination"
import { erroralert } from "helpers/toast.helper"
import { capitalizeFirstLetter, makeShortNumber } from "helpers/helper"

const Transaction = props => {

    document.title = "NFT"
    const [dateFilter, setDateFilter] = useState([])
    const [listingData, setData] = useState([])
    const [searchName, setSearchName] = useState("")
    const [statusFilter, setStatusFilter] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [loaderStatus, setLoaderStatus] = useState(true)
    const [homeActive, setHomeActive] = useState(null)
    let [incermentInd, setIncrementInd] = useState(1)
    const perPage = 15

    //fetch users
    const fetchData = async (pagenumber, pagelimit, searchName = "") => {
        try {

            let res = await axios.get(
                apiRoute.getNFTList + `?page=${pagenumber}&limit=${pagelimit}&keyword=${searchName}`
            )
            if (res?.data?.status == false) {
                throw new Error(res?.data?.message)
            } else {
                console.log(res.data.data['docs']);
                setData(res.data.data['docs'])

                // let arr = res.data.data['docs'];
                // for (let index = 0; index < arr.length; index++) {
                //     const element = arr[index];
                //     if (element?.is_homepage_active) {
                //         setHomeActive(element?._id)
                //     }
                // }

                let pageCount = Math.ceil(res.data.data.totalDocs / perPage)
                setTotalCount(pageCount)
            }
        } catch (err) {
            // console.log(err.message, "<<--")
            erroralert(err)
        } finally {
            setLoaderStatus(false)
        }
    }


    useEffect(() => {
        fetchData(currentPage, perPage)
    }, [])
    // useEffect(() => {
    //     console.log(homeActive, "homeActive");

    // }, [homeActive])

    const handlePageClick = page => {
        setCurrentPage(page)
        fetchData(page, perPage, searchName)
    }

    let [timer, setTimer] = useState(null);
    const handleSearchClick = event => {
        setSearchName(event.target.value)
        setCurrentPage(1)

        clearTimeout(timer)
        let value = setTimeout(() => {
            fetchData(1, perPage, event.target.value)
        }, 200);
        setTimer(value);
    }

    const handleSetHome = async (id, status) => {
        try {

            setLoaderStatus(true)
            let res = await axios.put(
                apiRoute.updateNFTupdate, {
                is_homepage_active: status,
                item_id: id
            }
            );
            console.log(res, ">>>>res", "<<---status");
            fetchData(currentPage, perPage)

        } catch (err) {
            console.log(err)
        } finally {
            setLoaderStatus(false)
        }
    }
    
    const handleNftStatus = async (id, status) => {
        try {

            setLoaderStatus(true)
            let res = await axios.put(
                apiRoute.updateNFTupdate, {
                status: status,
                item_id: id
            }
            );
            console.log(res, ">>>>res", "<<---status");
            fetchData(currentPage, perPage)

        } catch (err) {
            console.log(err)
        } finally {
            setLoaderStatus(false)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom py-2">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">
                                            {" "}NFT{" "}
                                        </h5>

                                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                                            <div
                                                className="position-relative"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <label htmlFor="search-bar-0" className="search-label">
                                                    <span id="search-bar-0-label" className="sr-only">
                                                        Search this table
                                                    </span>
                                                    <input
                                                        id="search-bar-0"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`Search Name...`}
                                                        onChange={handleSearchClick}
                                                    />
                                                </label>
                                                <i className="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <Table
                                        id="tech-companies-1"
                                        className="table table-striped table-bordered"
                                    >
                                        <Thead>
                                            <Tr>
                                                <Th>Image</Th>
                                                <Th>Name</Th>
                                                <Th>Description</Th>
                                                <Th>Price</Th>
                                                <Th>Ownership</Th>
                                                <Th>Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {loaderStatus ? (
                                                <Tr className="d-none">
                                                    <Td colSpan="6"> Loading ... </Td>
                                                </Tr>
                                            ) : listingData?.length != 0 ? (
                                                listingData?.map((element, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <img src={`${BASE_URL}/images/collection/logo/${element?.thumb}`} alt="" className="rounded-circle avatar-xs" />
                                                            </td>
                                                            <td>{element?.name}</td>
                                                            <td>{element?.description}</td>
                                                            <td>{makeShortNumber(element?.price)}</td>
                                                            <td>{element?.current_owner?.username}</td>
                                                            <td>
                                                                <div className="d-flex gap-3 align-items-center">
                                                                    {capitalizeFirstLetter(element?.status)}
                                                                    {
                                                                        (!element?.is_homepage_active && element?.status == "listed") ?
                                                                            <Link
                                                                                to="#"
                                                                                className="text-primary"
                                                                                onClick={() => handleSetHome(element?._id, true)}
                                                                            >
                                                                                <i className="mdi mdi-progress-alert font-size-20" id="deletetooltip" />
                                                                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                                    Set on Home
                                                                                </UncontrolledTooltip>
                                                                            </Link> :
                                                                            (element?.status == "listed") ? <Link
                                                                                to="#"
                                                                                className="text-primary"
                                                                                onClick={() => handleSetHome(element?._id, false)}
                                                                            >
                                                                                <i className="mdi mdi-check-decagram font-size-20" id="deletetooltip1" />
                                                                                <UncontrolledTooltip placement="top" target="deletetooltip1">
                                                                                    Unset on Home
                                                                                </UncontrolledTooltip>
                                                                            </Link> : <Link
                                                                                to="#"
                                                                                className="text-primary"
                                                                                onClick={() => handleNftStatus(element?._id, 'listed')}
                                                                            >
                                                                                <i className="mdi mdi-format-list-text font-size-20" id="deletetooltip4" />
                                                                                <UncontrolledTooltip placement="top" target="deletetooltip4">
                                                                                    Listing
                                                                                </UncontrolledTooltip>
                                                                            </Link>
                                                                    }

                                                                    {

                                                                        (element?.status == "inactive") ? <Link
                                                                            to="#"
                                                                            className="text-primary"
                                                                            onClick={() => handleNftStatus(element?._id, 'active')}
                                                                        >
                                                                            <i className="mdi mdi-lock-open font-size-20" id="deletetooltip2" />
                                                                            <UncontrolledTooltip placement="top" target="deletetooltip2">
                                                                                Active
                                                                            </UncontrolledTooltip>
                                                                        </Link> : <Link
                                                                            to="#"
                                                                            className="text-primary"
                                                                            onClick={() => handleNftStatus(element?._id, 'inactive')}
                                                                        >
                                                                            <i className="mdi mdi-lock font-size-20" id="deletetooltip3" />
                                                                            <UncontrolledTooltip placement="top" target="deletetooltip3">
                                                                                Inactive
                                                                            </UncontrolledTooltip>
                                                                        </Link>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan={12} style={{ textAlign: "center" }}>
                                                        No Records Found
                                                    </td>
                                                </tr>
                                            )}
                                        </Tbody>
                                    </Table>

                                    <Row className="mt-2">
                                        <Col md={9}></Col>
                                        <Col md={3}>
                                            <Pagination
                                                {...bootstrap5PaginationPreset}
                                                current={currentPage}
                                                total={totalCount}
                                                onPageChange={page => handlePageClick(page)}
                                                className="pagination justify-content-end"
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}


export default Transaction
