import React, { useEffect, useState } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Input,
    FormGroup,
    Label,
    Button,
} from "reactstrap"

// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import * as Yup from "yup"
import { apiRoute } from "routes/apiRoutes"
import axios from "axios"
import { erroralert, toastalert } from "helpers/toast.helper"
import JoditEditor from 'jodit-react';

const TasksCreate = () => {

    //meta title
    document.title = "Settings";

    const [commision, setCommission] = useState(0);
    const [loaderStatus, setLoaderStatus] = useState(true)

    const [t_title, setTTitle] = useState('');
    const [t_description, setTDescrition] = useState('');
    const [t_id, setTID] = useState('');

    const [p_title, setPTitle] = useState('');
    const [p_description, setPDescrition] = useState('');
    const [p_id, setPID] = useState('');

    const [a_title, setATitle] = useState('');
    const [a_description, setADescrition] = useState('');
    const [a_id, setAID] = useState('');



    //fetch users
    const fetchData = async () => {
        try {

            setLoaderStatus(true);
            let res = await axios.get(`${apiRoute.adminItemInfo}?name=admin_commission`)
            if (res?.data?.status == false) {
                throw new Error(res?.data?.message)
            } else {
                // console.log(res.data, "<<------item info");
                setCommission(res.data.result.value)
            }

            let res1 = await axios.get(`${apiRoute.adminCMSlist}`)
            if (res1?.data?.status == false) {
                throw new Error(res1?.data?.message)
            } else {

                let arr = res1.data.data['docs'];

                const termIndex = arr.findIndex(function (item) {
                    return item.title.toLowerCase().includes('term');
                });
                if (termIndex >= 0) {
                    setTDescrition(arr[termIndex]?.description);
                    setTTitle(arr[termIndex]?.title);
                    setTID(arr[termIndex]?._id);
                }
                
                const privacyIndex = arr.findIndex(function (item) {
                    return item.title.toLowerCase().includes('privacy');
                });
                if (privacyIndex >= 0) {
                    setPDescrition(arr[privacyIndex]?.description);
                    setPTitle(arr[privacyIndex]?.title);
                    setPID(arr[privacyIndex]?._id);
                }

                const aboutIndex = arr.findIndex(function (item) {
                    return item.title.toLowerCase().includes('about');
                });
                if (aboutIndex >= 0) {
                    setADescrition(arr[aboutIndex]?.description);
                    setATitle(arr[aboutIndex]?.title);
                    setAID(arr[aboutIndex]?._id);
                }

            }
        } catch (err) {
            // console.log(err.message, "<<--")
            erroralert(err)
        } finally {
            setLoaderStatus(false)
        }
    }


    useEffect(() => {
        fetchData()
    }, [])

    const commssionValidation = useFormik({
        enableReinitialize: true,

        initialValues: {
            commision: commision,
        },
        validationSchema: Yup.object({
            commision: Yup.number().min(0).required("This field is required."),
        }),
        onSubmit: async (values) => {
            await updateCommission(values);
        },
    });

    const updateCommission = async (data) => {
        try {

            let res = await axios.post(apiRoute.updateCommission, {
                name: "admin_commission",
                value: data?.commision,
            })
            console.log(res?.data, "<<--- commission response");

            if (res?.data?.status == false) {
                throw new Error(res?.data?.message)
            } else {
                console.log(res?.data, "<<--- file uploaded");
                toastalert('success', 'Commission Updated!');
            }
        } catch (err) {
            console.log(err.message, "<<--")
            erroralert(err)
        } finally {
            setLoaderStatus(false)
        }
    }

    const termValidation = useFormik({
        enableReinitialize: true,

        initialValues: {
            title: t_title,
            description: t_description,
        },
        validationSchema: Yup.object({
            title: Yup.string().required("This field is required."),
            description: Yup.string().required("This field is required."),
        }),
        onSubmit: async (values) => {
            console.log(values, "<<<----------- values")
            await updateCMSDoc(values, t_id);
        },
    });

    const privacyValidation = useFormik({
        enableReinitialize: true,

        initialValues: {
            title: p_title,
            description: p_description,
        },
        validationSchema: Yup.object({
            title: Yup.string().required("This field is required."),
            description: Yup.string().required("This field is required."),
        }),
        onSubmit: async (values) => {
            console.log(values, "<<<----------- values")
            await updateCMSDoc(values, p_id);
        },
    });

    const aboutValidation = useFormik({
        enableReinitialize: true,

        initialValues: {
            title: a_title,
            description: a_description,
        },
        validationSchema: Yup.object({
            title: Yup.string().required("This field is required."),
            description: Yup.string().required("This field is required."),
        }),
        onSubmit: async (values) => {
            console.log(values, "<<<----------- values")
            await updateCMSDoc(values, a_id);
        },
    });


    const updateCMSDoc = async (data, id) => {
        try {

            let res;
            if (id) {

                res = await axios.put(`${apiRoute.adminCMSUpdate}?page_id=${id}`, {
                    title: data.title,
                    description: data.description,
                    pageslug: createSlug(data.title),
                    image: '',
                    status: 'active',
                })
            } else {

                res = await axios.post(apiRoute.adminCMSAdd, {
                    title: data.title,
                    description: data.description,
                    pageslug: createSlug(data.title),
                    image: '',
                    status: 'active',
                })
            }

            console.log(res?.data, "<<--- cms response");

            if (res?.data?.status == false) {
                throw new Error(res?.data?.message)
            } else {
                console.log(res?.data, "<<--- file uploaded");
                toastalert('success', res?.data?.message);
            }
        } catch (err) {
            console.log(err.message, "<<--")
            erroralert(err)
        } finally {
            setLoaderStatus(false)
        }
    }

    function createSlug(title) {
        return title
            .toLowerCase()                // Convert to lowercase
            .trim()                       // Remove leading and trailing spaces
            .replace(/[^a-z0-9\s-]/g, "") // Remove special characters
            .replace(/\s+/g, "-");        // Replace spaces with hyphens
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Admin Commission</CardTitle>
                                    <form className="outer-repeater"
                                        onSubmit={e => {
                                            e.preventDefault()
                                            commssionValidation.handleSubmit()
                                            return false
                                        }}>
                                        <div data-repeater-list="outer-group" className="outer">
                                            <div data-repeater-item className="outer">
                                                <FormGroup className="mb-4" row>
                                                    <Label
                                                        htmlFor="commission"
                                                        className="col-form-label col-lg-2"
                                                    >
                                                        Commission Amount
                                                    </Label>
                                                    <Col lg="10">
                                                        <Input
                                                            id="commission"
                                                            name="commission"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Amount..."
                                                            onBlur={commssionValidation.handleBlur}
                                                            onChange={(e) => setCommission(e.target.value)}
                                                            value={commision}
                                                        />
                                                        {commssionValidation.touched.title && commssionValidation.errors.title ? (
                                                            <div className="text-danger">
                                                                {commssionValidation.errors.title}
                                                            </div>
                                                        ) : null}
                                                    </Col>

                                                    <Col lg="2"></Col>
                                                    <Col lg="10">
                                                        <Button type="submit" color="primary" className="mt-2">
                                                            Update
                                                        </Button>
                                                    </Col>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Term and Conditions</CardTitle>
                                    <form className="outer-repeater"
                                        onSubmit={e => {
                                            e.preventDefault()
                                            termValidation.handleSubmit()
                                            return false
                                        }}>
                                        <div data-repeater-list="outer-group" className="outer">
                                            <div data-repeater-item className="outer">
                                                <FormGroup className="mb-4" row>
                                                    <Label
                                                        htmlFor="title"
                                                        className="col-form-label col-lg-2"
                                                    >
                                                        Title
                                                    </Label>
                                                    <Col lg="10">
                                                        <Input
                                                            id="title"
                                                            name="title"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Write here..."
                                                            onBlur={termValidation.handleBlur}
                                                            onChange={(e) => setTTitle(e.target.value)}
                                                            value={t_title}
                                                        />
                                                        {termValidation.touched.title && termValidation.errors.title ? (
                                                            <div className="text-danger">
                                                                {termValidation.errors.title}
                                                            </div>
                                                        ) : null}
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="mb-4" row>
                                                    <Label className="col-form-label col-lg-2">
                                                        Description
                                                    </Label>
                                                    <Col lg="10">

                                                        <JoditEditor
                                                            name="description"
                                                            id="description"
                                                            rows="5"
                                                            tabIndex={1} // tabIndex of textarea
                                                            onBlur={termValidation.handleBlur}
                                                            onChange={newContent => setTDescrition(newContent)}
                                                            value={t_description}
                                                        />
                                                        {termValidation.touched.description && termValidation.errors.description ? (
                                                            <div className="text-danger">
                                                                {termValidation.errors.description}
                                                            </div>
                                                        ) : null}
                                                    </Col>

                                                    <Col lg="2"></Col>
                                                    <Col lg="10">
                                                        <Button type="submit" color="primary" className="mt-2">
                                                            Update
                                                        </Button>
                                                    </Col>
                                                </FormGroup>

                                            </div>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Privacy Policy</CardTitle>
                                    <form className="outer-repeater"
                                        onSubmit={e => {
                                            e.preventDefault()
                                            privacyValidation.handleSubmit()
                                            return false
                                        }}>
                                        <div data-repeater-list="outer-group" className="outer">
                                            <div data-repeater-item className="outer">
                                                <FormGroup className="mb-4" row>
                                                    <Label
                                                        htmlFor="title"
                                                        className="col-form-label col-lg-2"
                                                    >
                                                        Title
                                                    </Label>
                                                    <Col lg="10">
                                                        <Input
                                                            id="title"
                                                            name="title"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Write here..."
                                                            onBlur={privacyValidation.handleBlur}
                                                            onChange={(e) => setPTitle(e.target.value)}
                                                            value={p_title}
                                                        />
                                                        {privacyValidation.touched.title && privacyValidation.errors.title ? (
                                                            <div className="text-danger">
                                                                {privacyValidation.errors.title}
                                                            </div>
                                                        ) : null}
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="mb-4" row>
                                                    <Label className="col-form-label col-lg-2">
                                                        Description
                                                    </Label>
                                                    <Col lg="10">

                                                        <JoditEditor
                                                            name="description1"
                                                            id="description1"
                                                            rows="5"
                                                            tabIndex={1} // tabIndex of textarea
                                                            onBlur={privacyValidation.handleBlur}
                                                            onChange={newContent => setPDescrition(newContent)}
                                                            value={p_description}
                                                        />
                                                        {privacyValidation.touched.description && privacyValidation.errors.description ? (
                                                            <div className="text-danger">
                                                                {privacyValidation.errors.description}
                                                            </div>
                                                        ) : null}
                                                    </Col>

                                                    <Col lg="2"></Col>
                                                    <Col lg="10">
                                                        <Button type="submit" color="primary" className="mt-2">
                                                            Update
                                                        </Button>
                                                    </Col>
                                                </FormGroup>

                                            </div>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">About Us</CardTitle>
                                    <form className="outer-repeater"
                                        onSubmit={e => {
                                            e.preventDefault()
                                            aboutValidation.handleSubmit()
                                            return false
                                        }}>
                                        <div data-repeater-list="outer-group" className="outer">
                                            <div data-repeater-item className="outer">
                                                <FormGroup className="mb-4" row>
                                                    <Label
                                                        htmlFor="title"
                                                        className="col-form-label col-lg-2"
                                                    >
                                                        Title
                                                    </Label>
                                                    <Col lg="10">
                                                        <Input
                                                            id="title"
                                                            name="title"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Write here..."
                                                            onBlur={aboutValidation.handleBlur}
                                                            onChange={(e) => setATitle(e.target.value)}
                                                            value={a_title}
                                                        />
                                                        {aboutValidation.touched.title && aboutValidation.errors.title ? (
                                                            <div className="text-danger">
                                                                {aboutValidation.errors.title}
                                                            </div>
                                                        ) : null}
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="mb-4" row>
                                                    <Label className="col-form-label col-lg-2">
                                                        Description
                                                    </Label>
                                                    <Col lg="10">

                                                        <JoditEditor
                                                            name="description1"
                                                            id="description1"
                                                            rows="5"
                                                            tabIndex={1} // tabIndex of textarea
                                                            onBlur={aboutValidation.handleBlur}
                                                            onChange={newContent => setADescrition(newContent)}
                                                            value={a_description}
                                                        />
                                                        {aboutValidation.touched.description && aboutValidation.errors.description ? (
                                                            <div className="text-danger">
                                                                {aboutValidation.errors.description}
                                                            </div>
                                                        ) : null}
                                                    </Col>

                                                    <Col lg="2"></Col>
                                                    <Col lg="10">
                                                        <Button type="submit" color="primary" className="mt-2">
                                                            Update
                                                        </Button>
                                                    </Col>
                                                </FormGroup>

                                            </div>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default TasksCreate
