import PropTypes, { element, func } from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  FormGroup,
  Input,
  InputGroup,
  UncontrolledTooltip,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "../../assets/scss/style.css"
// import { Modal, Form, Button } from "react-bootstrap"
import { useFormik } from "formik"
import * as Yup from "yup"

import "flatpickr/dist/themes/material_blue.css"

import { withTranslation } from "react-i18next"

import { apiRoute } from "routes/apiRoutes"
// import { LoaderContext } from "context/ContextProvider"
import axios from "axios"
// import { toast } from "react-toastify"
import { dateformat, dateTimeformat } from "admin/commonFunction"
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination"
import { erroralert } from "helpers/toast.helper"
import { capitalizeFirstLetter } from "helpers/helper"

const Transaction = props => {
  // const {
  //   Add,
  //   Edit,
  //   showEdit,
  //   handleShow,
  //   handelShow1,
  //   handleClose,
  //   setloading,
  // } = useContext(LoaderContext)

  document.title = "Users"
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [dateFilter, setDateFilter] = useState([])
  const [listingData, setData] = useState([])
  const [searchName, setSearchName] = useState("")
  const [statusFilter, setStatusFilter] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [loaderStatus, setLoaderStatus] = useState(true)
  let [incermentInd, setIncrementInd] = useState(1)
  const perPage = 15

  //fetch users
  const fetchData = async (pagenumber, pagelimit, searchName = "") => {
    try {

      let res = await axios
        .get(
          apiRoute.getUsers + `?page=${pagenumber}&limit=${pagelimit}&keyward=${searchName}`
        )
      if (res?.data?.status == false) {
        throw new Error(res?.data?.message)
      } else {
        console.log('22');
        setData(res.data.data['docs'])
        // setData(res.data.data.items)

        let pageCount = Math.ceil(res.data.data.totalDocs / perPage)
        setTotalCount(pageCount)
        // console.log(totalCount, "totalCount")
        // setLoaderStatus(false)

      }
    } catch (err) {
      // console.log(err.message, "<<--")
      erroralert(err)
    } finally {
      setLoaderStatus(false)
    }
  }

  useEffect(() => {
    console.log(listingData.length);

  }, [listingData])

  useEffect(() => {
    fetchData(currentPage, perPage)
  }, [])

  const handlePageClick = page => {
    setCurrentPage(page)
    fetchData(page, perPage, searchName)
  }

  const handleSearchClick = event => {
    setSearchName(event.target.value)
    setCurrentPage(1)
    fetchData(1, perPage, event.target.value)
  }

  const handleStatusFilter = e => {
    console.log(e.target.value)
    setStatusFilter(e.target.value)
    if (e.target.value != "") {
      fetchData(1, perPage, "", e.target.value)
    } else {
      fetchData(1, perPage)
    }
  }

  const handleBlock = async (id, status) => {
    try {
      let res = await axios.post(apiRoute.updateUser, {
        status: status,
        user_id: id
      })
      if (res?.data?.status == false) {
        throw new Error(res?.data?.message)
      } else {
        fetchData(currentPage, perPage)
      }
    } catch (err) {
      erroralert(err)
    } finally {
      // setLoaderStatus(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">
                      {" "}Users{" "}
                    </h5>

                    <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                      <div
                        className="position-relative"
                        style={{ marginTop: "10px" }}
                      >
                        <label htmlFor="search-bar-0" className="search-label">
                          <span id="search-bar-0-label" className="sr-only">
                            Search this table
                          </span>
                          <input
                            id="search-bar-0"
                            type="text"
                            className="form-control"
                            placeholder={`Search Name...`}
                            onChange={handleSearchClick}
                          />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>Username</Th>
                        <Th>Wallet Address</Th>
                        <Th>Date</Th>
                        <Th>Email</Th>
                        <Th>Status</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {loaderStatus ? (
                        <Tr className="d-none">
                          <Td colSpan="6"> Loading ... </Td>
                        </Tr>
                      ) : listingData?.length != 0 ? (
                        listingData?.map((element, index) => {
                          return (
                            <tr key={index}>
                              <td>{element?.username}</td>
                              <td>{element?.metamask_info?.id}</td>
                              <td>{dateformat(element?.metamask_info)}</td>
                              <td>{element?.email}</td>
                              <td>{capitalizeFirstLetter(element?.status)}</td>
                              <td>
                                <>
                                  {
                                    element?.status == "active" ? <Link
                                      to="#"
                                      className="text-primary"
                                      onClick={() => handleBlock(element?._id, 'blocked')}
                                    >
                                      <i className="mdi mdi-account-off-outline font-size-18" id="deletetooltip" />
                                      <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Block
                                      </UncontrolledTooltip>
                                    </Link> :
                                      <Link
                                        to="#"
                                        className="text-primary"
                                        onClick={() => handleBlock(element?._id, 'active')}
                                      >
                                        <i className="mdi mdi-account-lock font-size-18" id="deletetooltip" />
                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                          Unblock
                                        </UncontrolledTooltip>
                                      </Link>
                                  }

                                </>
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={12} style={{ textAlign: "center" }}>
                            No Records Found
                          </td>
                        </tr>
                      )}
                    </Tbody>
                  </Table>

                  <Row className="mt-2">
                    <Col md={9}></Col>
                    <Col md={3}>
                      <Pagination
                        {...bootstrap5PaginationPreset}
                        current={currentPage}
                        total={totalCount}
                        onPageChange={page => handlePageClick(page)}
                        className="pagination justify-content-end"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

Transaction.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Transaction)
