
export const BASE_URL = 'https://apis.crypton.media';// process.env.REACT_APP_BASE_URL
console.log(BASE_URL, "BASEURL")

const apiRoute = {
  // getSubscribed: BASE_URL+'user/getsubscriptions',
  getUsers: BASE_URL + '/user/adminlist',
  getItemList: BASE_URL + '/customcollection/fulllist',
  updateCollection: BASE_URL + '/customcollection/update',
  getNFTList: BASE_URL + '/item/list',
  updateNFTupdate: BASE_URL + '/item/update',
  getCategories: BASE_URL + '/category/fulllist',
  uploadCategoriesMedia: BASE_URL + '/media/category',
  addCategoriesMedia: BASE_URL + '/category/add',
  deleteCategories: BASE_URL + '/category/delete',
  editCategories: BASE_URL + '/category/edit',
  addBanner: BASE_URL + '/settings/banner',
  getBanner: BASE_URL + '/settings/getbanner',
  getCommission: BASE_URL + '/item/adminhistory',
  getItemOffer: BASE_URL + '/item/offers',
  addSocial: BASE_URL + '/cms/socialadd',
  listSocial: BASE_URL + '/cms/socialadminlist',
  uploadSocialMedia: BASE_URL + '/media/social',
  deleteSocial: BASE_URL + '/cms/socialdelete',
  updateSocial: BASE_URL + '/cms/socialedit',
  updateUser: BASE_URL + '/user/updateuser',
  updateCommission: BASE_URL + '/settings/setoptions',
  adminItemInfo: BASE_URL + '/settings/getoptions',
  adminCMSlist: BASE_URL + '/cms/list',
  adminCMSAdd: BASE_URL + '/cms/add',
  adminCMSUpdate: BASE_URL + '/cms/edit',
}

export { apiRoute }
