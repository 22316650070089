import PropTypes, { element, func } from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    Badge,
    CardTitle,
    FormGroup,
    Input,
    InputGroup,
    UncontrolledTooltip
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "../../assets/scss/style.css"
// import { Modal, Form, Button } from "react-bootstrap"
import { useFormik } from "formik"
import * as Yup from "yup"

import "flatpickr/dist/themes/material_blue.css"

import { withTranslation } from "react-i18next"

import { apiRoute, BASE_URL } from "routes/apiRoutes"
import axios from "axios"
import { dateformat, dateTimeformat } from "admin/commonFunction"
import Pagination, {
    bootstrap5PaginationPreset,
} from "react-responsive-pagination"
import { erroralert } from "helpers/toast.helper"

const Transaction = props => {

    document.title = "Commission"
    const [dateFilter, setDateFilter] = useState([])
    const [listingData, setData] = useState([])
    const [searchName, setSearchName] = useState("")
    const [statusFilter, setStatusFilter] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [loaderStatus, setLoaderStatus] = useState(true)
    let [incermentInd, setIncrementInd] = useState(1)
    const perPage = 1500000

    //fetch users
    const fetchData = async (pagenumber, pagelimit, searchName = "") => {
        try {

            let res = await axios.get(
                apiRoute.getCommission + `?page=${pagenumber}&limit=${pagelimit}&keyword=${searchName}`
            )
            if (res?.data?.status == false) {
                throw new Error(res?.data?.message)
            } else {
                console.log(res.data.data['docs']);
                setData(res.data.data['docs'])
                let pageCount = Math.ceil(res.data.data.totalDocs / perPage)
                setTotalCount(pageCount)
            }
        } catch (err) {
            // console.log(err.message, "<<--")
            erroralert(err)
        } finally {
            setLoaderStatus(false)
        }
    }


    useEffect(() => {
        fetchData(currentPage, perPage)
    }, [])

    const handlePageClick = page => {
        setCurrentPage(page)
        fetchData(page, perPage, searchName)
    }

    let [timer, setTimer] = useState(null);
    const handleSearchClick = event => {
        setSearchName(event.target.value)
        setCurrentPage(1)

        clearTimeout(timer)
        let value = setTimeout(() => {
            fetchData(1, perPage, event.target.value)
        }, 200);
        setTimer(value);
    }

    const handleUpdateStatus = async (id, status) => {
        try {
            setLoaderStatus(true)
            axios.put(
                apiRoute.updateCollection, {
                collection_id: id,
                is_premium: status
            }
            )
                .then(res => {
                    console.log(res.data, "user listing >>>>>>")
                    fetchData(currentPage, perPage)
                    // setData(res.data.data['docs'])
                })
        } catch (err) {
            console.log(err)
        } finally {
            setLoaderStatus(false)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom py-2">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">
                                            {" "}Commission{" "}
                                        </h5>

                                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                                            <div
                                                className="position-relative"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <label htmlFor="search-bar-0" className="search-label">
                                                    <span id="search-bar-0-label" className="sr-only">
                                                        Search this table
                                                    </span>
                                                    <input
                                                        id="search-bar-0"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`Search Name...`}
                                                        onChange={handleSearchClick}
                                                    />
                                                </label>
                                                <i className="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <Table
                                        id="tech-companies-1"
                                        className="table table-striped table-bordered"
                                    >
                                        <Thead>
                                            <Tr>
                                                <Th>NFT</Th>
                                                <Th>Transaction Address</Th>
                                                <Th>Commission</Th>
                                                <Th>Created</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {loaderStatus ? (
                                                <Tr className="d-none">
                                                    <Td colSpan="6"> Loading ... </Td>
                                                </Tr>
                                            ) : listingData?.length != 0 ? (
                                                listingData?.map((element, index) => {
                                                    return (
                                                        (element?.history_type == "admin_comission") ?
                                                            <tr key={index}>
                                                                <td>{element?.item_id?.name}</td>
                                                                <td>{element?._id}</td>
                                                                <td>{element?.price}</td>
                                                                <td>{dateformat(element?.created_date)}</td>
                                                            </tr> : ''
                                                    )
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan={12} style={{ textAlign: "center" }}>
                                                        No Records Found
                                                    </td>
                                                </tr>
                                            )}
                                        </Tbody>
                                    </Table>

                                    <Row className="mt-2 d-none">
                                        <Col md={9}></Col>
                                        <Col md={3}>
                                            <Pagination
                                                {...bootstrap5PaginationPreset}
                                                current={currentPage}
                                                total={totalCount}
                                                onPageChange={page => handlePageClick(page)}
                                                className="pagination justify-content-end"
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}


export default Transaction
