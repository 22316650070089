import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export const toastalert = (type, message) => {
    toast[type](message, {
        position: "top-right"
    });
}

export const erroralert = (error) => {
    // console.log(error, "<<--- error");
    if(error?.message == "Token is not valid"){
        window.location.href = "/login"
    }
    
}