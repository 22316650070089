import React from "react"
import { Redirect } from "react-router-dom"

// // Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Admin Dashboard
import AdminDashboard from "../admin/dashboard/index"
import AdminChangePassword from "../admin/account/index"
import AdminDeals from "../admin/deals/index"
import AdminDealsView from "../admin/deals/view"
import AdminDealsCreate from "../admin/deals/create"
import AdminLiquidityPools from "../admin/liquidity/index"
import AdminUser from "../admin/users/index"
import AdminCollection from "../admin/collection/index"
import AdminNft from "../admin/nft/index"
import AdminCategory from "../admin/category/index"
import AdminBanners from "../admin/banners/index"
import AdminCommission from "../admin/commissions/index"
import AdminAuction from "../admin/auction/index"
import AdminSales from "../admin/sales/index"
import AdminSocial from "../admin/social/index"
import AdminSetting from "../admin/setting/index"

const authProtectedRoutes = [
  // Admin Routes
  { path: "/admin/dashboard", component: AdminDashboard },
  { path: "/admin/change-password", component: AdminChangePassword },
  { path: "/admin/deals", component: AdminDeals },
  { path: "/admin/deals/view", component: AdminDealsView },
  { path: "/admin/deals/create", component: AdminDealsCreate },
  { path: "/admin/liquidity-pools", component: AdminLiquidityPools },
  { path: "/admin/users", component: AdminUser },
  { path: "/admin/collections", component: AdminCollection },
  { path: "/admin/nft", component: AdminNft },
  { path: "/admin/categories", component: AdminCategory },
  { path: "/admin/banners", component: AdminBanners },
  { path: "/admin/commissions", component: AdminCommission },
  { path: "/admin/auction", component: AdminAuction },
  { path: "/admin/sales", component: AdminSales },
  { path: "/admin/social", component: AdminSocial },
  { path: "/admin/setting", component: AdminSetting },

  { path: "/dashboard", component: AdminDashboard },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },

]

export { authProtectedRoutes, publicRoutes }
