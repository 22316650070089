import PropTypes, { element, func } from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    Badge,
    CardTitle,
    FormGroup,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Button
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "../../assets/scss/style.css"
// import { Modal, Form, Button } from "react-bootstrap"
import { useFormik } from "formik"
import * as Yup from "yup"

import "flatpickr/dist/themes/material_blue.css"

import { withTranslation } from "react-i18next"

import { apiRoute, BASE_URL } from "routes/apiRoutes"
import axios from "axios"
import { dateformat, dateTimeformat } from "admin/commonFunction"
import Pagination, {
    bootstrap5PaginationPreset,
} from "react-responsive-pagination"
import { erroralert, toastalert } from "helpers/toast.helper"
import { capitalizeFirstLetter, makeShortNumber } from "helpers/helper"
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

const Transaction = props => {

    document.title = "Banners"
    const [modalShow, setModalShow] = useState(false);
    const [dateFilter, setDateFilter] = useState([])
    const [listingData, setData] = useState([])
    const [searchName, setSearchName] = useState("")
    const [statusFilter, setStatusFilter] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [loaderStatus, setLoaderStatus] = useState(true)
    const [homeActive, setHomeActive] = useState(null)
    const [editCategoryM, setEditCategoryM] = useState(null)
    let [incermentInd, setIncrementInd] = useState(1)
    const perPage = 15

    const [title, setTitle] = useState('');
    const [image, setImage] = useState('');
    const [alertimage, setAlertImage] = useState(false);

    const handleShow = () => setModalShow(!modalShow);

    //fetch users
    const fetchData = async (pagenumber, pagelimit, searchName = "") => {
        try {

            let res = await axios.get(
                apiRoute.getBanner + `?page=${pagenumber}&limit=${pagelimit}&keyword=${searchName}`
            )
            if (res?.data?.status == false) {
                throw new Error(res?.data?.message)
            } else {
                console.log(res.data.data['docs']);
                setData(res.data.data['docs'])

                let pageCount = Math.ceil(res.data.data.totalDocs / perPage)
                setTotalCount(pageCount)
            }
        } catch (err) {
            // console.log(err.message, "<<--")
            erroralert(err)
        } finally {
            setLoaderStatus(false)
        }
    }


    useEffect(() => {
        fetchData(currentPage, perPage)
    }, [])

    const handlePageClick = page => {
        setCurrentPage(page)
        fetchData(page, perPage, searchName)
    }

    let [timer, setTimer] = useState(null);
    const handleSearchClick = event => {
        setSearchName(event.target.value)
        setCurrentPage(1)

        clearTimeout(timer)
        let value = setTimeout(() => {
            fetchData(1, perPage, event.target.value)
        }, 200);
        setTimer(value);
    }

    // User Update and Validation
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            title: title ? title : editCategoryM?.title,
            imageFile: image,
        },
        validationSchema: Yup.object({
            title: Yup.string().required("This field is required."),
            // imageFile: Yup.required("This field is required"),
        }),
        onSubmit: async (values) => {
            if (!values.imageFile || values.imageFile.length == 0) {
                setAlertImage(true);
            } else {
                setAlertImage(false);
                await uploadCategoryMedia(values);
            }
        },
    })


    const uploadCategoryMedia = async (values) => {
        try {
            setLoaderStatus(true);

            let file = values.imageFile[0];
            let ext = (file.type == "image/png") ? ".png" : ".jpg"
            let avatarname = "admin_" + (new Date).getTime() + ext;
            let renamedFile = new File([file], avatarname, { type: file.type });

            const formData = new FormData();
            formData.append('file', renamedFile)
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            let res = await axios.post(apiRoute.uploadCategoriesMedia, {
                file: renamedFile
            }, config);
            if (res?.data?.status == false) {
                throw new Error(res?.data?.message)
            } else {
                console.log(res?.data, "<<--- file uploaded");
                await createCategory(values, avatarname);
            }

        } catch (err) {
            console.log(err.message, "<<--")
            erroralert(err)
        } finally {
            setLoaderStatus(false)
        }
    }

    const createCategory = async (values, avatarname) => {
        try {

            let res = await axios.post(apiRoute.addBanner, {
                media: avatarname,
                name: values.title,
            })
            if (res?.data?.status == false) {
                throw new Error(res?.data?.message)
            } else {
                console.log(res?.data, "<<--- file uploaded");
                toastalert('success', res?.data?.message);
                fetchData(currentPage, perPage);
                handleShow();
                setTitle('');
                setImage('');
            }
        } catch (err) {
            console.log(err.message, "<<--")
            erroralert(err)
        } finally {
            setLoaderStatus(false)
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom py-2">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">
                                            {" "}Banners{" "}
                                        </h5>

                                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block d-flex align-items-center gap-1">
                                            <div
                                                className="position-relative"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <label htmlFor="search-bar-0" className="search-label">
                                                    <span id="search-bar-0-label" className="sr-only">
                                                        Search this table
                                                    </span>
                                                    <input
                                                        id="search-bar-0"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`Search Name...`}
                                                        onChange={handleSearchClick}
                                                    />
                                                </label>
                                                <i className="bx bx-search-alt search-icon"></i>
                                            </div>
                                            <Button
                                                color="success"
                                                className="btn-rounded "
                                                onClick={handleShow}
                                            >
                                                + Add New
                                            </Button>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>

                                    <Row>
                                        {
                                            listingData && listingData.map((value, index) => {
                                                return <Col md={3} key={index}>
                                                    <div>
                                                        <img src={`${BASE_URL}/images/categories/${value?.media}`} alt="" className="img-thumbnail" />
                                                        <h5 className="my-1 ms-1">{value?.name}</h5>
                                                    </div>
                                                </Col>
                                            })
                                        }

                                    </Row>

                                    <Row className="mt-2">
                                        <Col md={9}></Col>
                                        <Col md={3}>
                                            <Pagination
                                                {...bootstrap5PaginationPreset}
                                                current={currentPage}
                                                total={totalCount}
                                                onPageChange={page => handlePageClick(page)}
                                                className="pagination justify-content-end"
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>


            <Modal show={modalShow} onHide={handleShow} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Create Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                        }}
                        className="validation-cls"
                    >

                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <label htmlFor="floatingnameInput">Title</label>
                                    <input
                                        type="text"
                                        name="title"
                                        className="form-control"
                                        id="floatingnameInput"
                                        placeholder="Enter Name..."
                                        onBlur={validation.handleBlur}
                                        onChange={(e) => setTitle(e.target.value)}
                                        value={title ? title : editCategoryM?.title}
                                    />
                                    {validation.touched.title && validation.errors.title ? (
                                        <div className="text-danger">
                                            {validation.errors.title}
                                        </div>
                                    ) : null}
                                </div>
                            </Col>
                            {
                                editCategoryM?.category_image ? <>
                                    <Col md={10}>
                                        <div className="mb-3">
                                            <label htmlFor="floatingnameInput">Image</label>
                                            <input
                                                type="file"
                                                name="image"
                                                className="form-control"
                                                id="floatingnameInput"
                                                placeholder="Enter Name..."
                                                onBlur={validation.handleBlur}
                                                onChange={(e) => setImage(e.target.files)}
                                                style={alertimage ? { border: "solid 1px red" } : {}}
                                            />
                                            {validation.touched.image && validation.errors.image ? (
                                                <div className="text-danger">
                                                    {validation.errors.image}
                                                </div>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col md={2} className="d-flex align-items-center">
                                        <img src={`${BASE_URL}/images/categories/${editCategoryM?.category_image}`} alt="" className="rounded-circle avatar-xs" />
                                    </Col>
                                </> :
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label htmlFor="floatingnameInput">Image</label>
                                            <input
                                                type="file"
                                                name="image"
                                                className="form-control"
                                                id="floatingnameInput"
                                                placeholder="Enter Name..."
                                                onBlur={validation.handleBlur}
                                                onChange={(e) => setImage(e.target.files)}
                                                style={alertimage ? { border: "solid 1px red" } : {}}
                                            />
                                            {validation.touched.image && validation.errors.image ? (
                                                <div className="text-danger">
                                                    {validation.errors.image}
                                                </div>
                                            ) : null}
                                        </div>
                                    </Col>
                            }

                        </Row>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                    }}>
                        {loaderStatus ? 'Loading...' : 'Save'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment >
    )
}


export default Transaction
