import PropTypes from "prop-types";
import React, { useState } from "react";
import { Alert, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
//redux
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";

//Social Media Imports
// import TwitterLogin from "react-twitter-auth"

// actions

// import images
import logo from "assets/images/favicon.png";
import profile from "assets/images/profile-img.png";

//Import config
0

import axios from 'axios';
import { useHistory } from 'react-router';
import "../../assets/css/style.css";
import { toastalert } from "../../helpers/toast.helper"
import { apiHeader } from "admin/commonFunction";

const Login = props => {

  //meta title
  document.title = "Login";

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      // dispatch(loginUser(values, props.history));
      adminLogin(values);
    }
  });

  const adminLogin = async (values) => {
    try {
      setLoader(true);

      let response = await axios.post('https://apis.crypton.media/user/login', values);
      if (response?.data?.status == false) {
        toastalert('error', response?.data?.message);
      } else {

        console.log(response.data, "<--response.data");

        localStorage.setItem("authUser", JSON.stringify({ 'uid': response.data.userId, 'role': 'admin', 'email': values.email, 'accessToken': response.data.token, 'type': 'admin', 'firstName': 'Admin' }));
        await apiHeader();
        setTimeout(() => {
          history.push('/admin/users');
        }, 500);
      }


    } catch (error) {
      console.log(error, 'eeeee');
      toastalert('error', response?.data?.message);
    } finally {

      setLoader(false);
    }

  }

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          {/* <i className="bx bx-home h2" /> */}
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">

        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft" style={{ background: '#590f1f' }}>
                  <Row>
                    <Col xs={7}>
                      <div className=" p-4 pe-1">
                        <h5 className="text-white">Welcome Back !</h5>
                        <p className="text-white">Sign in to continue to Crypto Media.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4 bg-soft" style={{ height: '4.5rem', width: '5.5rem' }}>
                        <span className="avatar-title rounded border" style={{ background: '#590f1f' }}>
                          <img
                            src={logo}
                            height="45"
                            className="mt-2"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Username</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Username"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          style={{ background: '#590f1f', border: '#181920' }}
                        >
                          {loader ? 'Loading...' : 'Log In'}
                        </button>
                      </div>

                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">

                <p className="text-white d-none">
                  © {new Date().getFullYear()} . {" "}
                  Design & Developed by Crypto Media
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
